import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import SEO from '../components/seo';
import '../styles/theme.scss';

const SchoolProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSchoolProject(
        sort: { fields: category } # filter:{ #  category: {eq: "Web Development"} # }
      ) {
        edges {
          node {
            projectName
            category
            slug
            featuredImage {
              fluid {
                ...GatsbyContentfulFluid_tracedSVG
              }
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  // console.log(data)
  return (
    <>
      <SEO title="School Projects" />
      <Container fluid>
        <Row className="pageHeader">
          <Col>
            <h1>SCHOOL PROJECTS</h1>
          </Col>
          <Col>
            <h5>
              This page contains the featured projects that I have done during
              my time at BCIT.
            </h5>
          </Col>
        </Row>
        <Row className="ProjectsContainer">
          {data.allContentfulSchoolProject.edges.map((edge, index) => {
            return (
              <Col key={index} xl="4" lg="6">
                <div className="ProjectWrapper">
                  <Link to={`/school-projects/${edge.node.slug}`}>
                    <div className="project-img">
                      {/* <img
                        src={edge.node.featuredImage.file.url}
                        alt={edge.node.featuredImage.title}
                      /> */}
                      <Img
                        fluid={edge.node.featuredImage.fluid}
                        alt={edge.node.featuredImage.title}
                      />
                    </div>
                  </Link>
                  <div className="desc">
                    <Link to={`/school-projects/${edge.node.slug}`}>
                      <h3>{edge.node.projectName}</h3>
                    </Link>
                    {/* <span><small> {edge.node.category} </small></span> */}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
export default SchoolProjects;
